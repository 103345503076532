module.exports = {
  siteTitle: 'CargoBay',
  manifestName: 'CargoBay',
  manifestShortName: 'CargoBay',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`,
  heading: 'Economize no transporte de sua mercadoria',
  subHeading: 'Somos uma empresa de tecnologia voltada à aplicações de logística para o comércio eletrônico.',

  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: '#',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: '#',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: '#',
    },
  ],
  email: 'contato@cargobay.com.br',
  phone: '000-00000',
  address: '',
};
