import React from 'react';
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { ToastContainer, toast } from 'react-toastify';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import Header from '../components/Header';

import axios from 'axios';

import '../../node_modules/react-toastify/dist/ReactToastify.css';

const IndexPage = () => {
  const [serverState, setServerState] = React.useState({
    submitting: false,
    status: null
  });
  
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    
    const form = e.target;
  
    setServerState({ submitting: true });
    
    axios({
      method: "post",
      url: "https://getform.io/f/d71781a3-560e-4b31-a430-c99986808ab0",
      data: new FormData(form)
    }).then(r => {
      handleServerResponse(true, "Thanks!", form);

      toast.success('Formulário enviado!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,        
      });
    }).catch(r => {
      handleServerResponse(false, r.response.data.error, form);
    });
  };

  return (
    <Layout>
      <Header />
      <ToastContainer />

      <header className="masthead" id="page-top">
        <div className="container h-100">
          <div className="row h-100 align-items-center justify-content-center text-center">
            <div className="col-lg-10 align-self-end">
              <h1 className="text-uppercase text-white font-weight-bold">
                {config.heading}
              </h1>
              <hr className="divider light my-4" />
            </div>
            <div className="col-lg-8 align-self-baseline">
              <p className="text-white-75 font-weight-light mb-5">
                {config.subHeading}
              </p>
              <Scroll type="id" element="o-que-fazemos">
                {/* <a
                  className="btn btn-primary btn-xl js-scroll-trigger"
                  href="#o-que-fazemos"
                >
                  Faça uma cotação
                </a> */}
              </Scroll>
            </div>
          </div>
        </div>
      </header>

      <section className="page-section bg-dark" id="o-que-fazemos">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-white mt-0">O que fazemos</h2>
              <hr className="divider light my-4" />
              <p className="text-white-75 mb-4">
                A CARGOBAY é uma empresa de tecnologia voltada à aplicações de logística para o comércio eletrônico.
              </p>
              <p className="text-white-75 mb-4">
                Operamos como transportadora de carga fracionada desde a coleta na origem até a entrega no destino.
                Nosso foco é o equilíbrio entre o preço e o prazo, melhorando a oferta do custo de frete para o remetente e
                o menor tempo de espera para o destinatário.
              </p>            
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="nossos-servicos">
        <div className="container">
          <h2 className="text-center mt-0">Nossos Serviços</h2>
          <hr className="divider my-4" />
          <div className="row">
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <div class="icons">
                  <i className="fas fa-4x fa-box text-primary mb-4"></i>                
                </div>
                <h3 className="h4 mb-2">Balcão a Balcão</h3>
                <p className="text-muted mb-0">
                  O Remetente entrega a encomenda em uma das agências parceiras na 
                  origem e o destinatário retira em outra agência no destino.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <div class="icons">
                  <i className="fas fa-4x fa-box text-primary mb-4"></i>
                  <i className="fas fa-4x fa-door-open text-primary mb-4"></i>
                </div>
                <h3 className="h4 mb-2">Balcão a Porta</h3>
                <p className="text-muted mb-0">
                  O Remetente entrega a encomenda em uma agência parceira na origem e 
                  entregamos no endereço do destinatário.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <div class="icons">
                  <i className="fas fa-4x fa-door-open text-primary mb-4"></i>
                  <i className="fas fa-4x fa-box text-primary mb-4"></i>
                </div>              
                <h3 className="h4 mb-2">Porta a Balcão</h3>
                <p className="text-muted mb-0">
                  Coletamos a encomenda no endereço do remetente e o destinatário 
                  retira em uma agência parceira no destino.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <div class="icons">
                  <i className="fas fa-4x fa-door-open text-primary mb-4"></i>                
                </div>
                <h3 className="h4 mb-2">Porta a Porta</h3>
                <p className="text-muted mb-0">
                  Coletamos a encomenda no endereço do remetente e entregamos 
                  no endereço do destinatário.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section bg-dark text-white" id="o-que-oferecemos">
        <div className="container text-center">
          <h2 className="mb-4">Oferecemos</h2>
          <hr className="divider light my-4" />
          <div className="row">
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-dollar-sign text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Cotação de preços e contratação online
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-credit-card text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Pagamento por cartão de crédito ou a faturar
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-box text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                Coleta na origem e entrega no destino ou despacho e retirada no balcão
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-clock text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Pontualidade: O despacho da encomenda é imediato ao próximo 
                  horário de saída do veículo ao destino.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-user-shield text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Seguro contra danos e roubo do envio ao recebimento
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-map-marker-alt text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Rastreamento de ponta a ponta e previsão de entrega
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 text-center">
              <div className="mt-5">
                <i className="fas fa-4x fa-exchange-alt text-white mb-4"></i>
                <p className="mb-0 text-white-75">
                  Devolução da encomenda ao remetente em caso de não recebimento no destino
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section" id="contato">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">Entre em Contato</h2>
              <hr className="divider my-4" />            
            </div>
          </div>
          <div className="row justify-content-center">          
            <div class="col-lg-12 col-md-12">            
              <div class="d-flex flex-row justify-content-center align-items-center">
                <form id="contact-form" method="POST" role="form" onSubmit={handleOnSubmit}>
                  <div class="messages" />
                  <div class="controls">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="form_name">Nome</label>
                                <input id="form_name" type="text" name="name" class="form-control" required="required" />
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="form_email">Email</label>
                                <input id="form_email" type="email" name="email" class="form-control" />
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label for="form_phone">Telefone</label>
                                <input id="form_phone" type="tel" name="phone" class="form-control" />
                                <div class="help-block with-errors"></div>
                            </div>
                        </div>
                    </div>                  
                  </div>
                  <div class="clearfix" />
                  <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="form_message">Mensagem</label>
                            <textarea id="form_message" name="message" class="form-control" rows="4" required="required"></textarea>
                            <div class="help-block with-errors"></div>
                        </div>
                    </div>
                    <div class="col-md-12">
                      <input type="submit"
                        class="btn btn-primary btn-send"
                        value="Enviar"
                        disabled={serverState.submitting} />
                    </div>
                  </div>            
                </form>            
              </div>
            </div>
          </div>        
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
